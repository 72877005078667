import { useForm } from "@formspree/react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";

const ContactForm = () => {
    const { t, i18n } = useTranslation();

    if (!i18n.hasLoadedNamespace("component-ContactForm")) {
        throw new Error(
            "Cannot use ContactForm without component-ContactForm namespace"
        );
    }

    const [state, handleSubmit] = useForm("xjvlpgvn");

    if (state.succeeded) {
        return (
            <p className="text-center text-3xl">
                {t("component-ContactForm:thanksForContactingUs")}
            </p>
        );
    }

    const defaultInputClassNames =
        "w-full pt-1 pl-1 mb-4 border-b-2 border-lightBrown";

    return (
        <form className="w-full flex flex-col" onSubmit={handleSubmit}>
            <label>
                <b>
                    {t("component-ContactForm:emailAddress:label")}
                    <span className="text-gold">*</span>
                </b>
                <input
                    required
                    className={defaultInputClassNames}
                    type="email"
                    name="email"
                    placeholder={t(
                        "component-ContactForm:emailAddress:placeholder"
                    )}
                />
            </label>

            <label>
                <b>
                    {t("component-ContactForm:name:label")}
                    <span className="text-gold">*</span>
                </b>
                <input
                    required
                    className={defaultInputClassNames}
                    type="text"
                    name="name"
                    placeholder={t("component-ContactForm:name:placeholder")}
                />
            </label>

            <label>
                <b>{t("component-ContactForm:phone:label")}</b>
                <input
                    className={defaultInputClassNames}
                    type="tel"
                    name="phone"
                    placeholder={t("component-ContactForm:phone:placeholder")}
                />
            </label>

            <label>
                <b>
                    {t("component-ContactForm:message:label")}
                    <span className="text-gold">*</span>
                </b>
                <textarea
                    required
                    className={`bg-transparent ${defaultInputClassNames}`}
                    name="message"
                    placeholder={t("component-ContactForm:message:placeholder")}
                />
            </label>

            <b>
                {t("component-ContactForm:privacy:label")}
                <span className="text-gold">*</span>
            </b>
            <label className="mb-4">
                <input
                    required
                    className="mr-2"
                    type="checkbox"
                    name="privacy"
                />
                {t("component-ContactForm:privacy:description")}
            </label>

            <input type="text" name="_gotcha" className="hidden" />

            <div className="text-center mb-4">
                <button
                    type="submit"
                    disabled={state.submitting}
                    className="bg-darkBrown border-transparent rounded-lg text-white px-4 py-2 uppercase"
                >
                    {t("component-ContactForm:send")}
                </button>
            </div>
        </form>
    );
};

export default ContactForm;
