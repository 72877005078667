import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import ContactForm from "../../components/ContactForm";
import Container from "../../components/Container";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";
import Separator from "../../components/Separator";

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "page-contatti", "component-ContactForm"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default function Contatti() {
    const { t } = useTranslation();

    return (
        <Layout>
            <Seo title={t("page-contatti:title")} />
            <Container>
                <StaticImage
                    src="../../../assets/images/contattaci.jpg"
                    alt={t("page-contatti:title")}
                    className="rounded-4xl"
                    aspectRatio={16 / 10}
                    layout="fullWidth"
                    transformOptions={{ fit: "cover" }}
                />
                <h1 className="mt-8">{t("page-contatti:title")}</h1>
                <div className="flex flex-col md:flex-row items-start md:items-center mt-2">
                    <b>{t("page-contatti:address:label")}:</b>
                    <span className="md:ml-4">
                        {t("page-contatti:address:value")}
                    </span>
                </div>
                <div className="flex flex-col md:flex-row items-start md:items-center mt-2">
                    <b>{t("page-contatti:phone")}:</b>
                    <span className="md:ml-4">
                        <a href="tel:+39 075.8040410" className="underline">
                            +39 075.8040410
                        </a>
                    </span>
                </div>
                <div className="flex flex-col md:flex-row items-start md:items-center mt-2">
                    <b>{t("page-contatti:email")}:</b>
                    <span className="md:ml-4">
                        <a
                            href="mailto:info@hotelmodernoassisi.com"
                            className="underline"
                        >
                            info@hotelmodernoassisi.com
                        </a>
                    </span>
                </div>
                <div className="flex flex-col mt-2 mb-4">
                    <b>{t("page-contatti:social")}:</b>
                    <div className="flex">
                        <a href="https://www.facebook.com/hotelmoderno.assisi">
                            <StaticImage
                                src="../../../assets/images/logo-facebook.png"
                                alt="Facebook"
                                layout="fixed"
                                width={50}
                            />
                        </a>
                        <a
                            href="https://www.instagram.com/hotel_moderno.assisi"
                            className="ml-4"
                        >
                            <StaticImage
                                src="../../../assets/images/logo-instagram.png"
                                alt="Instagram"
                                layout="fixed"
                                width={50}
                            />
                        </a>
                    </div>
                </div>
                <Separator />
                <ContactForm />
            </Container>
        </Layout>
    );
}
